@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aurochs";
  src: url("/fonts/Aurochs-Heavy.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Aurochs";
  src: url("/fonts/Aurochs-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aurochs";
  src: url("/fonts/Aurochs-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HooliganHandwritten";
  src: url("/fonts/HooliganHandwritten-Heavy04.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "HooliganHandwritten";
  src: url("/fonts/HooliganHandwritten-Regular04.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Bombing";
  src: url("/fonts/Bombing.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Adrip";
  src: url("/fonts/adrip1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "HooliganHandwritten", sans-serif;
  font-weight: 300;
  font-stretch: expanded;
}

.trait {
  font-family: "Aurochs", sans-serif;
  font-weight: 300;
  font-stretch: 200%;
  font-size: 2rem;
  text-shadow: 2px 2px 4px #000000;
  letter-spacing: 5px;
}

/* h1, h2, h3, h4, h5, h6, button {
 font-family: 'HooliganHandwritten', sans-serif;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1rem;
}

html,
body,
#__next {
  height: fit-content;
  min-height: fit-content;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* HOME PAGE HEX GRID */

.hex {
  position: absolute;
  width: 375px;
  height: 325px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.hex-svg {
  width: 100%;
  height: 100%;
}

.hex-image {
  clip-path: inherit;
}

.col1row1 {
  top: -15%;
  left: -30%;
  /* border: 1px solid orange; */
}

.col1row2 {
  top: 15.5%;
  left: -30%;
  /* border: 1px solid red; */
}

.col1row3 {
  top: 46%;
  left: -30%;
  /* border: 1px solid turquoise; */
}

.col1row4 {
  top: 76.5%;
  left: -30%;
  /* border: 1px solid green; */
}

.col2row1 {
  top: 0.5%;
  left: -15%;
  /* border: 1px solid white; */
}

.col2row2 {
  top: 31%;
  left: -15%;
  /* border: 1px solid red; */
}

.col2row3 {
  top: 61.5%;
  left: -15%;
  /* border: 1px solid blue; */
}

.col2row4 {
  top: 92%;
  left: -15%;
  /* border: 1px solid orange; */
}

.col3row1 {
  top: -15%;
  left: 0%;
  /* border: 1px solid orange; */
}

.col3row2 {
  top: 15.5%;
  left: 0%;
  /* border: 1px solid purple; */
}

.col3row3 {
  top: 46%;
  left: 0%;
  /* border: 1px solid turquoise; */
}

.col3row4 {
  top: 76.5%;
  left: 0%;
  /* border: 1px solid red; */
}

.col4row1 {
  top: 0.5%;
  left: 15%;
  /* border: 1px solid orange; */
}

.col4row2 {
  top: 31%;
  left: 15%;
  /* border: 1px solid blue; */
}

.col4row3 {
  top: 61.5%;
  left: 15%;
  /* border: 1px solid yellow; */
}

.col4row4 {
  top: 92%;
  left: 15%;
  /* border: 1px solid white; */
}

.col5row1 {
  top: -15%;
  left: 30%;
  /* border: 1px solid orange; */
}

.col5row2 {
  top: 15.5%;
  left: 30%;
  /* border: 1px solid green; */
}

.col5row3 {
  top: 46%;
  left: 30%;
  /* border: 1px solid purple; */
}

.col5row4 {
  top: 76.5%;
  left: 30%;
  /* border: 1px solid blue; */
}

.col6row1 {
  top: 0.5%;
  left: 45%;
  /* border: 1px solid blue; */
}

.col6row2 {
  top: 31%;
  left: 45%;
  /* border: 1px solid pink; */
}

.col6row3 {
  top: 61.5%;
  left: 45%;
  /* border: 1px solid orange; */
}

.col6row4 {
  top: 92%;
  left: 45%;
  /* border: 1px solid purple; */
}

.col7row1 {
  top: -15%;
  left: 60%;
  /* border: 1px solid white; */
}

.col7row2 {
  top: 15.5%;
  left: 60%;
  /* border: 1px solid green; */
}

.col7row3 {
  top: 46%;
  left: 60%;
  /* border: 1px solid white; */
}

.col7row4 {
  top: 76.5%;
  left: 60%;
  /* border: 1px solid yellow; */
}

.col8row1 {
  top: 0.5%;
  left: 75%;
  /* border: 1px solid green; */
}

.col8row2 {
  top: 31%;
  left: 75%;
  /* border: 1px solid white; */
}

.col8row3 {
  top: 61.5%;
  left: 75%;
  /* border: 1px solid yellow; */
}

.col8row4 {
  top: 92%;
  left: 75%;
  /* border: 1px solid green; */
}

/* Dashboard | User Nft Hex Grid */

.hex-wrapper {
  --s: 200px; /* size  */
  --m: 4px; /* margin */
  --f: calc(1.732 * var(--s) + 4 * var(--m) - 1px);
}

.container div {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  min-height: fit-content;
  display: inline-block;
  font-size: initial;
  /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); /* hex flat side up */
  clip-path: polygon(
    0% 25%,
    0% 75%,
    50% 100%,
    100% 75%,
    100% 25%,
    50% 0%
  ); /* hex point side up */
  margin-bottom: calc(var(--m) - var(--s) * 0.2885);
}

.container::before {
  content: "";
  width: calc(var(--s) / 2 + var(--m));
  float: left;
  height: 120%;
  shape-outside: repeating-linear-gradient(
    #0000 0 calc(var(--f) - 3px),
    #000 0 var(--f)
  );
}

.dash-hex {
  width: var(--s);
  height: calc(var(--s) * 1.1547);
  clip-path: polygon(
    0% 25%,
    0% 75%,
    50% 100%,
    100% 75%,
    100% 25%,
    50% 0%
  ); /* hex point side up */
}

.dash-hex .overlay {
  top: -6px;
  left: -7px;
  width: 105%;
  height: 105%;
  display: none; /* Hide overlay by default */
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  flex-direction: column;
  clip-path: inherit;
}

.dash-hex:hover .overlay {
  display: flex;
}

.metadata-item {
  margin-bottom: 4px;
  white-space: nowrap;
}

.hooliShadow {
  box-shadow:
    0 8px 16px rgba(0, 0, 0, 0.1),
    0 12px 24px rgba(0, 0, 0, 0.05),
    0 16px 32px rgba(0, 0, 0, 0.1),
    0 24px 48px rgba(0, 0, 0, 0.1),
    0 48px 64px rgba(0, 0, 0, 0.15),
    0 96px 128px rgba(0, 0, 0, 0.15);
}

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 18px;
  color: #fff;
}
.ribbon {
  --f: 0.5em; /* control the folded part*/
  --r: 0.8em; /* control the ribbon shape */

  position: absolute;
  left: 4px;
  top: calc(-1 * var(--f));
  padding: 0.2em;
  border-right: var(--f) solid #0005;
  border-bottom: var(--r) solid #0000;
  clip-path: polygon(
    calc(100% - var(--f)) 0,
    0 0,
    0 100%,
    calc(50% - var(--f) / 2) calc(100% - var(--r)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) var(--f),
    100% var(--f)
  );
  z-index: 100;
}
